<template>
  <div style="margin-top: 20px;">
    <el-button @click="dialogVisible=true" style="float: right">新增</el-button>
    <el-table
        :data="tableData"
        border
        style="width: 100%">
      <el-table-column
          fixed
          prop="otherContent.title"
          label="名称">
      </el-table-column>
      <el-table-column
          prop="otherContent.url"
          label="地址">
      </el-table-column>
      <el-table-column width="150"
                       label="图片">
        <template slot-scope="scope">
          <img :src="scope.row.otherContent['img'] " class="avatar" :fit="fit">
        </template>
      </el-table-column>
            <el-table-column
                prop="otherContent.otherIndex"
                label="排序">
            </el-table-column>
      <el-table-column
          fixed="right"
          label="操作">
        <template slot-scope="scope">
          <div>
            <el-button type="info" @click="updData(scope.row)">编辑</el-button>
          </div>
          <div>
            <el-button type="danger" @click="deleteBanner(scope.row)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="90%"
        :before-close="handleClose">
      <el-form :model="other" ref="dynamicValidateForm" label-width="40px">
        <el-form-item label="名称">
          <el-input v-model="other.otherContent.title"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="other.otherContent.url"></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <el-input v-model="other.otherContent.img"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="other.otherDesc"></el-input>
        </el-form-item>
        <el-form-item prop="usrEmail" label="排序">
          <el-input-number v-model="other.otherIndex" :min="0" :max="70" label="描述文字"/>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
    <el-button @click="cancel">取 消</el-button>
    <el-button type="primary" @click="submit">{{ operateType === 1 ? '新增' : '修改' }}</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import Expand from '@/request/expand.js';

export default {
  name: "admin_externalApp",
  data() {
    return {
      sysAsk: new Expand(),
      tableData: [],
      dialogVisible: false,
      tempImg: '',
      fit: "contain",
      desc: "",
      other: {
        otherContent: {
          "title": "",
          "img": "",
          "url": ""
        },
        "otherType": "external_app",
        "otherDesc": "",
        "otherIndex": 0
      },
      operateType: 1, // 1为新增 0为修改
      param: {"type": "external_app"},
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    updData(data) {
      this.dialogVisible = true
      this.operateType = 0
      this.other = data
      // this.fromData.bearImg = ""
      // this.tempImg = '/api/resources/public/banner/' + data['bearImg']
    },
    getData() {
      this.sysAsk.getOtherData(this.param).then(res => {
        this.tableData = []
        res.msg.forEach(item => {
          item['otherContent'] = JSON.parse(item['otherContent']);
          this.tableData.push(item)
        })
      })
    },
    deleteBanner(data) {
      this.sysAsk.delOtherData(data.otherId).then(res => {
        this.getData()
      })
    },
    show(data) {
      this.dialogVisible = true
      this.fromData = data
      this.tempImg = data['bearImg']
    },
    cancel() {
      this.fromData = {}
      this.tempImg = ''
      this.dialogVisible = false
    },
    submit() {
      if (this.operateType === 1) {
        this.sysAsk.insOtherData(this.other).then(res => {
          this.dialogVisible = false
          this.getData()
        })
      } else {
        this.sysAsk.updOtherData(this.other).then(res => {
          this.dialogVisible = false
          this.getData()
        })
      }
    },

    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    }
  },
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}

.avatar {
  width: 150px;
  height: 150px;
  display: block;
}
</style>